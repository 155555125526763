<template>
  <v-app>
    <v-app-bar src="/header.jpg" app dark>
      <div class="d-flex align-center">
        <h1>ambienceWork</h1>
      </div>

      <v-spacer></v-spacer>

      <v-btn v-if="!openSettings" @click="openSettings = true" text>
        <span class="mr-2">Einstellungen</span>
        <v-icon>mdi-cog</v-icon>
      </v-btn>
      <v-btn v-if="openSettings" @click="openSettings = false" text>
        <span class="mr-2">Einstellungen</span>
        <v-icon>mdi-cog</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-container>
        <v-row class="text-center">
          <v-col>
            <v-progress-circular
              :rotate="-90"
              :size="300"
              :width="40"
              max="1500"
              min="0"
              :value="((1500 - totalTime) / 1500) * 100"
              :color="currentPomodorStageColor"
            >
              <div>
                {{ minutes }}:{{ seconds }} <br />
                <template v-for="trackSection in trackSections">
                  <div :key="trackSection.name" v-if="trackSection.active">
                    {{ trackSection.name }}
                  </div>
                </template>
              </div>
            </v-progress-circular>
          </v-col>
          <v-col class="col-4" v-show="openSettings">
            <v-container>
              <h3>Steuerung</h3>

              <v-container>
                <v-btn v-if="!muted" @click="mute()">Mute</v-btn>
                <v-btn v-if="muted" @click="unmute()">Unmute</v-btn>
              </v-container>
              <v-btn @click="chooseRandomSample(0)">chooseRandomSample</v-btn>
            </v-container>

            <v-container>
            <h4>Pomodoro-Timer ({{totalTime}}s)</h4>
            <v-slider max="1500" min="0" v-model="totalTime"></v-slider>
            </v-container>
            
          </v-col>
        </v-row>

        <v-row class="text-center">
          <v-col>
            <v-container>
              <v-btn
                :color="transportStarted ? 'success' : ''"
                @click="startTransport()"
                >ambienceWork initialisieren</v-btn
              >
            </v-container>

            <v-container v-if="transportStarted">
              <v-btn v-if="!timer" @click="startTimer"> Klangwelt Starten  </v-btn>

              <v-btn v-if="timer" @click="stopTimer"> Klangwelt stoppen </v-btn>

              <v-btn v-if="resetButton" @click="resetTimer">
                Timer neustarten
              </v-btn>
            </v-container>
            <v-container>
              <transition-group name="slideDown" tag="div">
                <template v-for="ambienceSound in ambienceSounds">
                  <v-chip
                    class="ma-2"
                    color="primary"
                    label
                    :key="ambienceSound.file"
                    v-if="ambienceSound.active"
                    @click="loopManagement(ambienceSound, '@1m')"
                  >
                    <v-icon left> mdi-music-note </v-icon>
                    {{ ambienceSound.file }}
                  </v-chip>
                </template>
              </transition-group>
            </v-container>
          </v-col>

          <v-col class="col-2" v-show="openSettings">
            <h4>Tageszeit</h4>
            <v-radio-group v-model="tageszeit" @change="tageszeitChange()">
              <v-radio label="Morgens" :value="0"></v-radio>
              <v-radio label="Mittags" :value="1"></v-radio>
              <v-radio label="Abends" :value="2"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col class="col-2" v-show="openSettings">
            <h4>Lautstärke</h4>
            <v-slider
           @change="volumeChange()"
              max="20"
              min="-20"
              vertical
              v-model="volume"
            ></v-slider>

            {{ volume }} dB
          </v-col>
        </v-row>

        <v-row v-show="openSettings">
          <v-expansion-panels popout multiple>
            <v-expansion-panel>
              <v-expansion-panel-header>
                Ambience-Abschnitte
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                Jeder 25-Minuten Pomodoro-Timer ist in verschiedene Segmente
                unterteilt. Diese bilden verschiedene Stimmungen und Gefühle ab.
                Jedes Segment beeinhaltet Haupt- und Nebenelemente.
                Diese werden zufällig kombiniert.
               
                <v-container>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Abschnitt</th>
                          <th class="text-left">Abschnitt-Timing</th>
                          <th v-if="currentStage == 1" class="text-left">
                            <b>Stage1</b>
                          </th>
                          <th v-else class="text-left">Stage1</th>
                          <th v-if="currentStage == 2" class="text-left">
                            <b>Stage2</b>
                          </th>
                          <th v-else class="text-left">Stage2</th>
                          <th v-if="currentStage == 3" class="text-left">
                            <b>Stage3</b>
                          </th>
                          <th v-else class="text-left">Stage3</th>
                          <th v-if="currentStage == 4" class="text-left">
                            <b>Stage4</b>
                          </th>
                          <th v-else class="text-left">Stage4</th>
                          <th v-if="currentStage == 5" class="text-left">
                            <b>Stage5</b>
                          </th>
                          <th v-else class="text-left">Stage5</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="trackSection in trackSections"
                          :key="trackSection.name"
                          class="text-left"
                        >
                          <td v-if="trackSection.active">
                            <b>{{ trackSection.name }}</b>
                          </td>
                          <td v-else>{{ trackSection.name }}</td>
                          <td>ab {{ trackSection.timing }}:59</td>
                          <td v-if="trackSection.active && currentStage == 1">
                            <b>{{ trackSection.stage1 }}</b>
                          </td>
                          <td v-else>{{ trackSection.stage1 }}</td>
                          <td v-if="trackSection.active && currentStage == 2">
                            <b>{{ trackSection.stage2 }}</b>
                          </td>
                          <td v-else>{{ trackSection.stage2 }}</td>
                          <td v-if="trackSection.active && currentStage == 3">
                            <b>{{ trackSection.stage3 }}</b>
                          </td>
                          <td v-else>{{ trackSection.stage3 }}</td>
                          <td v-if="trackSection.active && currentStage == 4">
                            <b>{{ trackSection.stage4 }}</b>
                          </td>
                          <td v-else>{{ trackSection.stage4 }}</td>
                          <td v-if="trackSection.active && currentStage == 5">
                            <b>{{ trackSection.stage5 }}</b>
                          </td>
                          <td v-else>{{ trackSection.stage5 }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-container>

           
                 <v-container>
                  <h4>currentStage: {{ currentStage }}</h4>
            <v-slider
              hint="currentStage"
              color="green"
              ticks="always"
              tick-size="4"
              max="7"
              min="0"
              readonly
              v-model="currentStage"
            ></v-slider>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header>
                Ambience-Elemente
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                Die verschiedenen Elemente, welche kombiniert werden können, um eine
                Ambience-Stimmung abzubilden.
                <v-container>
                  <v-row
                    class="text-center"
                    v-if="transportStarted && openSettings"
                  >
                    <v-col
                      v-for="ambienceSound in ambienceSounds"
                      :key="ambienceSound.file"
                    >
                      <v-btn
                        :color="ambienceSound.active ? 'success' : ''"
                        @click="loopManagement(ambienceSound, '@1m')"
                        >{{ ambienceSound.file }}</v-btn
                      >
                      <v-switch
                        v-model="ambienceSound.loop"
                        label="Loopen"
                      ></v-switch>
                      <v-slider
                        hint="Lautstärke"
                        dense
                        max="20"
                        min="-40"
                        :label="ambienceSound.volume.toString()"
                        v-model="ambienceSound.volume"
                        @change="setVolume(ambienceSound)"
                      ></v-slider>
                    </v-col>
                  </v-row>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <v-col v-show="openSettings"> </v-col>
        </v-row>
      </v-container>
       <v-snackbar
      v-model="snackbar"
    >
      Klangwelt gestartet. In wenigen Sekunden sollten die ersten Klänge starten.

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          timeout="8000"
          v-bind="attrs"
          @click="snackbar = false"
        >
          OK
        </v-btn>
      </template>
    </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
import * as Tone from "tone";
import Timer from "tiny-timer";

// Load Chance
var Chance = require("chance");

// Instantiate Chance so it can be used
var chance = new Chance();

require("vue2-animate/dist/vue2-animate.min.css");

const timer = new Timer({ interval: 10000 });

timer.on("tick", function (ms) {
  console.log("tick", ms);
});
timer.on("done", () => console.log("done!"));
timer.on("statusChanged", (status) => console.log("status:", status));

//create a synth and connect it to the main output (your speakers)
//const synth = new Tone.Synth().toDestination();

/*
Tone.Transport.scheduleRepeat(function() {
  //console.log("ok");
  //synth.triggerAttackRelease("C4", "8n");
}, "1m");
*/

// If you want these variables to be global, then use `window` (if you're
// in a browser) instead of your own object.
var sampleBasepath = "samples/";
var fileExtension = ".ogg";

// Aufteilung in Moods
// Tageszeit + Ruhig

export default {
  name: "App",

  components: {},
  mounted: function () {
    for (var key of Object.keys(this.ambienceSounds)) {
      // console.log(key + " -> " + this.ambienceSounds[key]);

      // Tone.js New player erstellen in object array
      this.ambienceSounds[key].player = new Tone.Player(
        sampleBasepath + this.ambienceSounds[key].file + fileExtension
      ).toDestination();

      this.ambienceSounds[key].player.debug = true;
      // Tone.js OnEnd Aktion festlegen
      //this.ambienceSounds[key].player.onstop = this.startTimer();
    }

    Tone.loaded().then(() => {
      console.log("alle audio files geladen");
    });
    console.log("Ambience-Sounds Array erstellt.");
    console.log(this.ambienceSounds);

    this.trackSections = this.trackSectionsMittags;
  },

  data: () => ({
    loopStarted: false,
    transportStarted: false,
    zufallszahl: null,
    volume: 0,
    anzahlTodos: 15,
    tageszeit: 1,
    totalTime: 25 * 60,
    currentMinute: null,
    resetButton: false,
    timer: null,
    openSettings: false,
    currentStage: 0,
    snackbar: false,
    // ANCHOR trackSection
    trackSections: [],
    trackSectionsMittags: [
      {
        name: "Slow Intro",
        timing: "24",
        color: "primary",
        active: false,
        stage1: "pad",
        stage2: "piano",

        stage4: "guitar",
                stage5: "tiny",
      },
      {
        name: "FastPiano",
        timing: "21",
        color: "red",
        active: false,
        stage1: "piano",
        stage2: "pianoFast",
        stage5: "liveDrums",
      },
      {
        name: "guitarMood",
        timing: "20",
        color: "yellow",
        active: false,
        stage1: "padMorning",
        stage2: "bassMorning",
        stage3: "guitarMorning",
      },
      {
        name: "Heavy Elements",
        timing: "17",
        color: "green",
        active: false,
        stage1: "pad",
        stage2: "piano",
        stage3: "tiny",
        stage4: "guitar",
      },
      {
        name: "MorningPads",
        timing: "14",
        color: "green",
        active: false,
        stage1: "padMorning",
        stage4: "padMorning",
      },
      {
        name: "Everything",
        timing: "11",
        color: "green",
        active: false,
        stage1: "pad",
        stage2: "piano",
        stage3: "tiny",
        stage5: "guitar",
      },
      {
        name: "Ruhige Letzte Minute",
        timing: "0",
        color: "green",
        active: false,
        stage1: "pad",
        stage3: "tiny",
      },
    ],
    // ANCHOR trackSectionAbends
        trackSectionsAbends: [
      {
        name: "Abends Pad Intro",
        timing: "24",
        color: "primary",
        active: false,
        stage1: "nightPads",
       
      },
      {
        name: "Abends Rhodes Jam",
        timing: "22",
        color: "red",
        active: false,
                stage1: "liveDrums",
        stage2: "nightRhodes",


      },
      {
        name: "Pluck Jam Simple",
        timing: "17",
        color: "yellow",
        active: false,
        stage1: "nightPads",
        stage2: "nightPlucks",
      },
      {
        name: "Rhodes",
        timing: "14",
        color: "green",
        active: false,
        stage1: "nightPads",
        stage2: "nightRhodes",

      },
      {
        name: "MorningPads",
        timing: "11",
        color: "green",
        active: false,
        stage1: "nightPads",
      },
      {
        name: "RestSong2",
        timing: "5",
        color: "green",
        active: false,
        stage1: "nightPads",
        stage2: "nightRhodes",
      },
      {
        name: "Action Letzte Minute",
        timing: "0",
        color: "green",
        active: false,
        stage1: "nightPlucks",
      },
    ],
    currentTrackSection: null,
    currentPomodorStage: null,
    currentPomodorStageColor: "primary",
    muted: false,
    // ANCHOR AmbienceSounds
    ambienceSounds: [
      {
        file: "cinematicPianoFast/p75",
        active: false,
        loop: false,
        volume: -11,
        player: null,
        type: "pianoFast",
      },
      {
        file: "cinematicPianoFast/p103",
        active: false,
        loop: false,
        volume: -18,
        player: null,
        type: "pianoFast",
      },
      {
        file: "cinematicPiano/p76",
        active: false,
        loop: false,
        volume: -15,
        player: null,
        type: "piano",
      },
      {
        file: "cinematicPiano/p77",
        active: false,
        loop: false,
        volume: -11,
        player: null,
        type: "piano",
      },
      {
        file: "cinematicPiano/p78",
        active: false,
        loop: false,
        volume: -11,
        player: null,
        type: "piano",
      },
      {
        file: "cinematicPiano/p79",
        active: false,
        loop: false,
        volume: -11,
        player: null,
        type: "piano",
      },
      {
        file: "cinematicPiano/p80",
        active: false,
        loop: false,
        volume: -19,
        player: null,
        type: "piano",
      },
      {
        file: "cinematicPiano/p81",
        active: false,
        loop: false,
        volume: -13,
        player: null,
        type: "piano",
      },
      {
        file: "cinematicPiano/p82",
        active: false,
        loop: false,
        volume: -19,
        player: null,
        type: "piano",
      },
      {
        file: "cinematicPiano/p94",
        active: false,
        loop: false,
        volume: -19,
        player: null,
        type: "piano",
      },
      {
        file: "longWarmPad/p83",
        active: false,
        loop: false,
        volume: -30,
        player: null,
        type: "pad",
      },
      {
        file: "longWarmPad/p84",
        active: false,
        loop: false,
        volume: -24,
        player: null,
        type: "timerMinute",
      },
      {
        file: "longWarmPad/p85",
        active: false,
        loop: false,
        volume: -30,
        player: null,
        type: "pad",
      },
      {
        file: "longWarmPad/p86",
        active: false,
        loop: false,
        volume: -30,
        player: null,
        type: "pad",
      },
      {
        file: "longWarmPad/p87",
        active: false,
        loop: false,
        volume: -30,
        player: null,
        type: "pad",
      },
      {
        file: "longWarmPad/p88",
        active: false,
        loop: false,
        volume: -28,
        player: null,
        type: "pad",
      },
      {
        file: "rhodesPrePiano/p89",
        active: false,
        loop: false,
        volume: -4,
        player: null,
        type: "piano",
      },
      {
        file: "rhodesPrePiano/p90",
        active: false,
        loop: false,
        volume: 1,
        player: null,
        type: "piano",
      },
      {
        file: "rhodesPrePiano/p104",
        active: false,
        loop: false,
        volume: -6,
        player: null,
        type: "piano",
      },
      {
        file: "hangDrum/p91",
        active: false,
        loop: false,
        volume: -11,
        player: null,
        type: "tiny",
      },
      {
        file: "hangDrum/p92",
        active: false,
        loop: false,
        volume: -11,
        player: null,
        type: "tiny",
      },
      {
        file: "hangDrum/p93",
        active: false,
        loop: false,
        volume: -11,
        player: null,
        type: "tiny",
      },
      {
        file: "guitar/p95",
        active: false,
        loop: false,
        volume: 1,
        player: null,
        type: "guitar",
      },
      {
        file: "guitar/p96",
        active: false,
        loop: false,
        volume: -5,
        player: null,
        type: "guitar",
      },
      {
        file: "guitar/p105",
        active: false,
        loop: false,
        volume: -5,
        player: null,
        type: "guitar",
      },
      {
        file: "guitarMorning/p112",
        active: false,
        loop: false,
        volume: -5,
        player: null,
        type: "guitarMorning",
      },
      {
        file: "guitarMorning/p113",
        active: false,
        loop: false,
        volume: -5,
        player: null,
        type: "guitarMorning",
      },
      {
        file: "vocalStretch/vs02",
        active: false,
        loop: false,
        volume: -25,
        player: null,
        type: "pad",
      },
      {
        file: "liveDrums/dr00",
        active: false,
        loop: false,
        volume: 4,
        player: null,
        type: "liveDrums",
      },
      {
        file: "liveDrumsFast/dr01",
        active: false,
        loop: false,
        volume: -14,
        player: null,
        type: "liveDrumsFast",
      },
      {
        file: "liveDrums/dr02",
        active: false,
        loop: false,
        volume: -14,
        player: null,
        type: "liveDrums",
      },
      {
        file: "spacePads/sp00",
        active: false,
        loop: false,
        volume: -40,
        player: null,
        type: "pad",
      },
      {
        file: "spacePads/sp02",
        active: false,
        loop: false,
        volume: -40,
        player: null,
        type: "pad",
      },
      {
        file: "spacePads/sp03",
        active: false,
        loop: false,
        volume: -40,
        player: null,
        type: "pad",
      },
      {
        file: "spacePads/sp01",
        active: false,
        loop: false,
        volume: -40,
        player: null,
        type: "pad",
      },
      {
        file: "spacePadsMorning/p107",
        active: false,
        loop: false,
        volume: -15,
        player: null,
        type: "padMorning",
      },
      {
        file: "spacePadsMorning/p108",
        active: false,
        loop: false,
        volume: -15,
        player: null,
        type: "padMorning",
      },
      {
        file: "spacePadsMorning/p109",
        active: false,
        loop: false,
        volume: -15,
        player: null,
        type: "padMorning",
      },
      {
        file: "spacePadsMorning/p110",
        active: false,
        loop: false,
        volume: -15,
        player: null,
        type: "padMorning",
      },
      {
        file: "spacePadsMorning/p111",
        active: false,
        loop: false,
        volume: -15,
        player: null,
        type: "padMorning",
      },
      {
        file: "bassMorning/p114",
        active: false,
        loop: false,
        volume: -0,
        player: null,
        type: "bassMorning",
      },
      {
        file: "bassMorning/p115",
        active: false,
        loop: false,
        volume: -0,
        player: null,
        type: "bassMorning",
      },

      {
        file: "nightRhodes/p116",
        active: false,
        loop: false,
        volume: -11,
        player: null,
        type: "nightRhodes",
      },
      {
        file: "nightRhodes/p117",
        active: false,
        loop: false,
        volume: -11,
        player: null,
        type: "nightRhodes",
      },

      {
        file: "nightRhodes/p122",
        active: false,
        loop: false,
        volume: -11,
        player: null,
        type: "nightRhodes",
      },

      {
        file: "nightPlucks/p123",
        active: false,
        loop: false,
        volume: -11,
        player: null,
        type: "nightPlucks",
      },
      {
        file: "nightPlucks/p124",
        active: false,
        loop: false,
        volume: -11,
        player: null,
        type: "nightPlucks",
      },
      {
        file: "nightPlucks/p125",
        active: false,
        loop: false,
        volume: -11,
        player: null,
        type: "nightPlucks",
      },
      {
        file: "nightPlucks/p126",
        active: false,
        loop: false,
        volume: -11,
        player: null,
        type: "nightPlucks",
      },
      {
        file: "nightPads/p118",
        active: false,
        loop: false,
        volume: -11,
        player: null,
        type: "nightPads",
      },
      {
        file: "nightPads/p119",
        active: false,
        loop: false,
        volume: -11,
        player: null,
        type: "nightPads",
      },
      {
        file: "nightPads/p120",
        active: false,
        loop: false,
        volume: -11,
        player: null,
        type: "nightPads",
      },
      {
        file: "nightPads/p121",
        active: false,
        loop: false,
        volume: -11,
        player: null,
        type: "nightPads",
      },
      {
        file: "nightPads/p127",
        active: false,
        loop: false,
        volume: -11,
        player: null,
        type: "nightPads",
      },
      {
        file: "nightPads/p128",
        active: false,
        loop: false,
        volume: -11,
        player: null,
        type: "nightPads",
      },
      {
        file: "nightPads/p129",
        active: false,
        loop: false,
        volume: -11,
        player: null,
        type: "nightPads",
      },
      {
        file: "nightPads/p130",
        active: false,
        loop: false,
        volume: -11,
        player: null,
        type: "nightPads",
      },
      {
        file: "nightPads/p131",
        active: false,
        loop: false,
        volume: -11,
        player: null,
        type: "nightPads",
      },
      {
        file: "nightPads/p132",
        active: false,
        loop: false,
        volume: -11,
        player: null,
        type: "nightPads",
      },

      {
        file: "soundEffects/fire",
        active: false,
        loop: true,
        volume: -25,
        player: null,
        type: "ambience",
      },
      {
        file: "timerPing/tp2",
        active: false,
        loop: false,
        volume: -21,
        player: null,
        type: "timerMinute",
      },
      {
        file: "timerPing/tp3",
        active: false,
        loop: false,
        volume: -15,
        player: null,
        type: "timerMinute",
      },
      {
        file: "soundEffects/endPing",
        active: false,
        loop: false,
        volume: -16,
        player: null,
        type: "endPing",
      },
    ],
  }),
  // ANCHOR Methods
  methods: {
    startTimer: function () {
      this.timer = setInterval(() => this.countdown(), 1000);
      this.resetButton = true;
      this.snackbar = true;
      this.title = "Greatness is within sight!!";
    },
    stopTimer: function () {
      clearInterval(this.timer);
      this.timer = null;
      this.resetButton = true;
      this.title = "Never quit, keep going!!";
    },
    resetTimer: function () {
      this.totalTime = 25 * 60;
      clearInterval(this.timer);
      this.timer = null;
      this.resetButton = false;
      this.title = "Let the countdown begin!!";
    },
    padTime: function (time) {
      return (time < 10 ? "0" : "") + time;
    },
    tageszeitChange: function () {

      if (this.tageszeit == 1 || this.tageszeit == 0) {
      this.trackSections = [];
      this.trackSections = this.trackSectionsMittags;
      } 
      if (this.tageszeit == 2) {
         this.trackSections = [];
      this.trackSections = this.trackSectionsAbends;
      } 

     
    },

    countdown: function () {
      if (this.totalTime >= 1) {
        this.totalTime--;
      } else {
        this.totalTime = 0;
        this.resetTimer();
        // TODO Endereignis ausführen
        this.chooseRandomSample(99);
      }
    },
    async startTransport() {
      await Tone.start();
      Tone.Transport.bpm.value = 90;
      Tone.Transport.start();

      // timer.start(15000) // run for 5 seconds
      console.log("audio is ready");
      this.transportStarted = true;

      // Zufallszahlen generieren
      this.zufallszahl = chance.natural({ min: 1, max: 20 });
    },
    setTrackSectionActive(trackSection) {
      for (var key of Object.keys(this.trackSections)) {
        // Wenn
        if (key == trackSection) {
          this.trackSections[key].active = true;
        } else {
          this.trackSections[key].active = false;
        }
      }
    },
    mute() {
      Tone.Master.mute = true;
      this.muted = true;
    },
    volumeChange: function () {
      console.log(this.volume);
        Tone.Master.volume.value = this.volume;
        console.log("volume change")
    },
    unmute() {
      Tone.Master.mute = false;
      this.muted = false;
    },
    chooseRandomSample(currentStage) {
      // Track-Abschnitt-Gestaltung
      this.currentStage = currentStage;

      console.log("Random-Sample wird gestartet.");
      var selectedType = "";

      this.currentMinute = parseInt(this.totalTime / 60);

      // ANCHOR MITTAGS
      if (this.tageszeit == 1) {
        // Minute 25-20
        if (22 <= this.currentMinute && this.currentMinute <= 25) {
          this.setTrackSectionActive(0);
          if (currentStage == 0) {
            // selectedType = "piano";
          }

          if (currentStage == 1) {
            selectedType = "pad";
          }
          if (currentStage == 2) {
            selectedType = "piano";
          }
          if (currentStage == 3) {
          //
          }
          if (currentStage == 4) {
            selectedType = "guitar";
          }
          if (currentStage == 5) {
              selectedType = "tiny";
          }
          if (currentStage == 6) {
            // selectedType = "tiny";
            // selectedType = "timerMinute";
          }
        }

        // Minute 19-18
        if (21 <= this.currentMinute && this.currentMinute <= 21) {
          this.setTrackSectionActive(1);

          if (currentStage == 0) {
            // selectedType = "piano";
          }

          if (currentStage == 1) {
            selectedType = "pad";
          }
          if (currentStage == 2) {
            selectedType = "pianoFast";
          }
          if (currentStage == 3) {
            // selectedType = "tiny";
          }
          if (currentStage == 4) {
            // selectedType = "guitar";
          }
          if (currentStage == 5) {
            if (chance.integer({ min: 0, max: 100 }) > 20) {
              selectedType = "liveDrums";
            }
          }
          if (currentStage == 6) {
            //  selectedType = "tiny";
            // selectedType = "timerMinute";
          }
        }

        // Guitar Mood
        if (18 <= this.currentMinute && this.currentMinute <= 20) {
          this.setTrackSectionActive(2);

          if (currentStage == 0) {
            //
          }

          if (currentStage == 1) {
            selectedType = "padMorning";
          }
          if (currentStage == 2) {
            selectedType = "bassMorning";
          }
          if (currentStage == 3) {
            selectedType = "guitarMorning";
            //  selectedType = "tiny";
          }
          if (currentStage == 4) {
            // selectedType = "guitar";
          }
          if (currentStage == 5) {
            // selectedType = "piano";
          }
          if (currentStage == 6) {
            // selectedType = "tiny";
            // selectedType = "timerMinute";
          }
        }

        if (15 <= this.currentMinute && this.currentMinute <= 17) {
          this.setTrackSectionActive(3);

          if (currentStage == 0) {
            //
          }

          if (currentStage == 1) {
            selectedType = "pad";
          }
          if (currentStage == 2) {
            selectedType = "piano";
          }
          if (currentStage == 3) {
            selectedType = "tiny";
          }
          if (currentStage == 4) {
            selectedType = "guitar";
          }
          if (currentStage == 5) {
            // selectedType = "piano";
          }
          if (currentStage == 6) {
            // selectedType = "tiny";
            // selectedType = "timerMinute";
          }
        }

        // Nur morning Pads
        if (12 <= this.currentMinute && this.currentMinute <= 14) {
          this.setTrackSectionActive(4);

          if (currentStage == 0) {
            //
          }

          if (currentStage == 1) {
            selectedType = "padMorning";
          }
          if (currentStage == 2) {
            // selectedType = "piano";
          }
          if (currentStage == 3) {
            // selectedType = "tiny";
          }
          if (currentStage == 4) {
            selectedType = "padMorning";
          }
          if (currentStage == 5) {
            // selectedType = "piano";
          }
          if (currentStage == 6) {
            // selectedType = "tiny";
            // selectedType = "timerMinute";
          }
        }

        if (1 <= this.currentMinute && this.currentMinute <= 11) {
          this.setTrackSectionActive(5);

          if (currentStage == 0) {
            //
          }

          if (currentStage == 1) {
            selectedType = "pad";
          }
          if (currentStage == 2) {
            selectedType = "piano";
          }
          if (currentStage == 3) {
            selectedType = "tiny";
          }
          if (currentStage == 4) {
            //
          }
          if (currentStage == 5) {
            selectedType = "guitar";
          }
          if (currentStage == 6) {
            // selectedType = "tiny";
            // selectedType = "timerMinute";
          }
        }

        if (0 <= this.currentMinute && this.currentMinute <= 0) {
          this.setTrackSectionActive(6);

          if (currentStage == 0) {
            // selectedType = "piano";
          }

          if (currentStage == 1) {
            selectedType = "pad";
          }
          if (currentStage == 2) {
            //selectedType = "piano";
          }
          if (currentStage == 3) {
            selectedType = "tiny";
          }
          if (currentStage == 4) {
            // selectedType = "guitar";
          }
          if (currentStage == 5) {
            // selectedType = "piano";
          }
          if (currentStage == 6) {
            // selectedType = "tiny";
            // selectedType = "timerMinute";
          }
        }
      }


      // ANCHOR ABENDS
      if (this.tageszeit == 2) {

              
        if (23 <= this.currentMinute && this.currentMinute <= 25) {
          this.setTrackSectionActive(0);


          if (currentStage == 1) {
            selectedType = "nightPads";
          }
         
        }

      
        if (18 <= this.currentMinute && this.currentMinute <= 22) {
          this.setTrackSectionActive(1);


if (currentStage == 1) {
            if (chance.integer({ min: 0, max: 100 }) > 30) {
              selectedType = "liveDrums";
            }
          }
          if (currentStage == 2) {
            selectedType = "nightRhodes";
          }
          
         
         
         
        }

        // Guitar Mood
        if (15 <= this.currentMinute && this.currentMinute <= 17) {
          this.setTrackSectionActive(2);

        

          if (currentStage == 1) {
            selectedType = "nightPads";
          }
          if (currentStage == 2) {
            selectedType = "nightPlucks";
          }
        
         
        }

        if (12 <= this.currentMinute && this.currentMinute <= 14) {
          this.setTrackSectionActive(3);

         

                   if (currentStage == 1) {
            selectedType = "nightPads";
          }
                    if (currentStage == 2) {
            selectedType = "nightRhodes";
          }
         
        }

        // Nur morning Pads
        if (6 <= this.currentMinute && this.currentMinute <= 11) {
          this.setTrackSectionActive(4);


          if (currentStage == 1) {
            selectedType = "nightPads";
          }
         
        }

        if (1 <= this.currentMinute && this.currentMinute <= 5) {
          this.setTrackSectionActive(5);

          

           if (currentStage == 1) {
            selectedType = "nightPads";
          }
                    if (currentStage == 2) {
            selectedType = "nightRhodes";
          }
        }

        if (0 <= this.currentMinute && this.currentMinute <= 0) {
          this.setTrackSectionActive(3);

        

         
          if (currentStage == 1) {
            selectedType = "nightPlucks";
          }
         
        }

      }

      // Stage 7 bleibt immer gleich, Minuten Timer
      if (currentStage == 7) {
        // timerPing nur ausführen bei 20, 15, 10 und 5 minuten
        if (this.currentMinute == 20) {
          selectedType = "timerMinute";
          console.log("Es sind wieder fünf Minuten rum");
        }
        if (this.currentMinute == 15) {
          selectedType = "timerMinute";
          console.log("Es sind wieder fünf Minuten rum");
        }
        if (this.currentMinute == 10) {
          selectedType = "timerMinute";
          console.log("Es sind wieder fünf Minuten rum");
        }
        if (this.currentMinute == 5) {
          selectedType = "timerMinute";
          console.log("Es sind wieder fünf Minuten rum");
        }
        if (this.currentMinute == 0) {
          selectedType = "endPing";
          console.log("Es sind wieder fünf Minuten rum");
        }
      }

      if (selectedType) {
        // Aussortieren
        let ambienceTypeSorted = [];
        for (let i = 0; i < this.ambienceSounds.length; i++) {
          if (this.ambienceSounds[i].type == selectedType) {
            // console.log(this.ambienceSounds[i])

            if (!this.ambienceSounds[i].active) {
                ambienceTypeSorted.push(this.ambienceSounds[i]);
            }
            else {
              console.log("Da war einer schon aktiv!")
            }

            
          }
        }
        console.log("ambienceTypeSortedArray: ");
        console.log(ambienceTypeSorted);
        var randomItem =
          ambienceTypeSorted[
            Math.floor(Math.random() * ambienceTypeSorted.length)
          ];

        if (this.timer) {
          this.loopManagement(randomItem, "@1m");
        }
      }
    },

    loopManagement(ambienceSound, startAt) {
      console.log(ambienceSound);
      var player = ambienceSound.player;
      player.loop = ambienceSound.loop;
      player.volume.value = ambienceSound.volume;

      if (!ambienceSound.active) {
        Tone.Transport.schedule(function () {
          //player.fadeOut = 1;
          player.sync().start();
          player.onstop = () => {
            console.log("Sound " + ambienceSound.file + " gestoppt.");
            ambienceSound.active = false;
          };
          ambienceSound.active = true;
          console.log(player.state + " " + ambienceSound.file);
        }, startAt);
      }

      if (ambienceSound.active) {
        //  BUG BEHOBEN
        // großes Problem: Wenn Datei gerade noch ausspielt, 
      // dann wird die nicht neu gestartet bisher --> eventuell
      // leere wenn zufällig gleiches sample zeimal kommt
         player.sync().stop();
        //p5.loop = false;

        //  player.stop();
        // player.sync().start();
        // player.sync().stop();
        // ambienceSound.active = false;
        console.log(player.state);
      }
    },
    setVolume(ambienceSound) {
      var player = ambienceSound.player;
      player.volume.value = ambienceSound.volume;
    },

    playSound() {
      //play a middle 'C' for the duration of an 8th note
      Tone.Transport.bpm.value = 128;
      Tone.Transport.start();
      // synth.triggerAttackRelease("C4", "8n");
    },
  },
  computed: {
    minutes: function () {
      const minutes = Math.floor(this.totalTime / 60);
      return this.padTime(minutes);
    },
    seconds: function () {
      const seconds = this.totalTime - this.minutes * 60;

      if (this.transportStarted) {
        if (seconds == 59 && this.currentStage != 1) {
          this.chooseRandomSample(1);
        }
        if (seconds == 49 && this.currentStage != 2) {
          this.chooseRandomSample(2);
        }
        if (seconds == 39 && this.currentStage != 3) {
          this.chooseRandomSample(3);
        }
        if (seconds == 29 && this.currentStage != 4) {
          this.chooseRandomSample(4);
        }
        if (seconds == 19 && this.currentStage != 5) {
          this.chooseRandomSample(5);
        }
        if (seconds == 9 && this.currentStage != 6) {
          this.chooseRandomSample(6);
        }
        if (seconds == 1 && this.currentStage != 7) {
          this.chooseRandomSample(7);
        }
      }
      return this.padTime(seconds);
    },
  },
};
</script>
<style scoped>
.selectedRow {
  font-weight: bold;
}
</style>